import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react';
export const Bucket = ({
  A: 'a',
  B: 'b'
} as const);
export type Bucket = (typeof Bucket)[keyof typeof Bucket];
export type ExperimentationVariant = {
  id: string;
  label: string;
  value: string;
};
const Context = createContext<[Bucket, Dispatch<SetStateAction<Bucket>>]>([Bucket.A, () => {}]);
type ExperimentsProviderProps = {
  children: ReactNode;
  bucket: Bucket;
};
export function ExperimentsProvider({
  children,
  bucket
}: ExperimentsProviderProps) {
  const bucketState = useState(bucket);
  return <Context.Provider value={bucketState} data-sentry-element="unknown" data-sentry-component="ExperimentsProvider" data-sentry-source-file="experiments.tsx">{children}</Context.Provider>;
}
export function usePreviewableBucket(previewBucket?: Bucket): Bucket {
  const [bucket, setBucket] = useContext(Context);
  const lastPreviewBucket = useRef(previewBucket);
  useEffect(() => {
    if (lastPreviewBucket.current !== previewBucket && previewBucket != null) {
      lastPreviewBucket.current = previewBucket;
      setBucket(previewBucket);
    }
  }, [previewBucket, setBucket]);
  return bucket;
}