import { useRouter } from 'next/router';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import clsx from 'clsx';
import useSWR from 'swr';
import { EntryOrder, FeaturedContentBlockDocument, ImageAssetFragment } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { normalizeImage } from '@/lib/contentful/normalizers/image';
import { isDevelopment } from '@/utils/isDevelopment';
import getLocalizedNameFromEnglish from '@/utils/locales/localized-routes/getLocalizedNameFromEnglish';
import { Card } from '@/components/Card';
import { FullScreenLoading } from '@/components/FullScreenLoading';
import { Warning } from '@/components/Warning';
import { getBaseUrl, isRichText, serializeCacheKey } from '../../../../../lib/contentful/utils';
export const FeaturedContentType = ({
  WithImage: 'with-image',
  WithoutImage: 'without-image'
} as const);
type FeaturedContentType = (typeof FeaturedContentType)[keyof typeof FeaturedContentType];
export const FeaturedContentOrderBy = EntryOrder;
type FeaturedContentOrderBy = (typeof FeaturedContentOrderBy)[keyof typeof FeaturedContentOrderBy];
type Props = {
  className?: string;
  tag?: string;
  layout?: FeaturedContentType;
  defaultImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  orderBy?: FeaturedContentOrderBy;
  count?: number;
};
export const FEATURED_CONTENT_DEFAULT_FEATURED_TAG = 'collectionTest';
const DEFAULT_IMAGE = ({
  url: 'https://storage.googleapis.com/s.mkswft.com/RmlsZTo4ZmJiYTYzMy1jOGUwLTQ4ZWEtOTRiNy1iZmUyZjRkYWIwMDU=/BigCommerce-Social-Image-Generic-Facebook.jpg',
  width: 1200,
  height: 630
} as const);
export const DEFAULT_COUNT = 4;
export function FeaturedContentBlock({
  className,
  tag = FEATURED_CONTENT_DEFAULT_FEATURED_TAG,
  layout = FeaturedContentType.WithImage,
  orderBy = EntryOrder.SysPublishedAtDesc,
  count = DEFAULT_COUNT
}: Props) {
  const {
    locale = 'en-US',
    isPreview
  } = useRouter();
  const contentful = client({
    preview: isPreview
  });
  const {
    data,
    error,
    isLoading
  } = useSWR(serializeCacheKey('featuredContentBlock', {
    locale,
    tag,
    count,
    orderBy
  }), () => contentful.request(FeaturedContentBlockDocument, {
    where: {
      contentfulMetadata: {
        tags_exists: true,
        tags: {
          id_contains_some: [tag]
        }
      }
    },
    order: [orderBy],
    limit: count,
    locale,
    preview: isPreview
  }));
  const withImage = layout === FeaturedContentType.WithImage;
  if (isLoading) return <FullScreenLoading />;
  if (error) {
    if (isDevelopment()) {
      return <Warning>{error?.message || 'An error occurred'}</Warning>;
    } else {
      console.error(error);
      return null;
    }
  }
  if (data?.entryCollection?.items?.length === 0) {
    if (isDevelopment()) {
      return <Warning className={className}>No content found for Contentful Tag: {tag}</Warning>;
    }
    return null;
  }
  return <div className={clsx(className, 'grid gap-8', 'lg:grid-cols-3')} data-sentry-component="FeaturedContentBlock" data-sentry-source-file="FeaturedContentBlock.tsx">
			{data?.entryCollection?.items?.map((item, idx) => {
      if (item == null) return <div key={idx}>Item cannot be found</div>;
      const key = item.sys.id;
      const isFeatured = withImage ? idx % 4 === 1 || idx % 4 === 2 : false;
      const className = clsx(isFeatured && 'lg:col-span-2');
      const bgColor = '#121118';
      const truncateText = true;
      function getImageSrc(image?: ImageAssetFragment | null) {
        return isFeatured ? normalizeImage(image ?? {
          ...DEFAULT_IMAGE,
          alt: 'Bigcommerce',
          __typename: 'Asset'
        }) : undefined;
      }
      function getText(text?: string) {
        return withImage ? text : undefined;
      }
      if (item.__typename === 'BlogPost') {
        return <Card key={key} className={className} imageSrc={getImageSrc(item?.featuredImage)} isFeatured={isFeatured} truncateText={truncateText} bgColor={bgColor} title={item.title ?? 'Title'} linkText="Read more" imageAlt={item.title ?? 'Image alt'} heading={'blog'} text={getText(isRichText(item.summary) ? documentToPlainTextString(item.summary.json) : '')} linkURL={{
          href: `${getBaseUrl(locale)}/${getLocalizedNameFromEnglish({
            locale,
            name: 'blog'
          })}/${item.slug}`
        }} />;
      }
      if (item.__typename === 'Article') {
        return <Card key={key} className={className} imageSrc={getImageSrc(item?.featuredImage)} isFeatured={isFeatured} truncateText={truncateText} bgColor={bgColor} title={item.title ?? 'Title'} linkText="Read more" imageAlt={item.title ?? 'Image alt'} heading={'article'} text={getText(item.interstitialHeadline ?? 'Interstitial headline')} linkURL={{
          href: `${getBaseUrl(locale)}/${getLocalizedNameFromEnglish({
            locale,
            name: 'articles'
          })}${item.parentArticle?.slug ? '/' + item.parentArticle?.slug : ''}/${item.slug}`
        }} />;
      }
      if (item.__typename === 'Resource') {
        return <Card key={key} className={className} imageSrc={getImageSrc(item?.featuredImage)} isFeatured={isFeatured} truncateText={truncateText} bgColor={bgColor} title={item.hero?.headline ?? 'Title'} linkText="Download now" imageAlt={item.hero?.headline ?? 'Image alt'} heading={item.type?.toLowerCase() ?? 'resource'} linkURL={{
          href: `${getBaseUrl(locale)}/${getLocalizedNameFromEnglish({
            locale,
            name: 'resources'
          })}/${getLocalizedNameFromEnglish({
            locale,
            name: `${item.type?.toLowerCase() ?? 'guide'}s`
          })}/${item.slug}`
        }} text={getText(item.hero?.subheading && isRichText(item.hero.subheading) ? documentToPlainTextString(item.hero.subheading.json) : 'Hero subheading')} />;
      }
      if (item.__typename === 'CaseStudy') {
        return <Card key={key} className={className} imageSrc={getImageSrc(item?.image)} isFeatured={isFeatured} truncateText={truncateText} bgColor={bgColor} title={item.hero?.headline ?? 'Title'} linkText="Read now" imageAlt={item.hero?.headline ?? 'Image alt'} heading={'case study'} linkURL={{
          href: `${getBaseUrl(locale)}/${getLocalizedNameFromEnglish({
            locale,
            name: 'case-study'
          })}/${item.slug}`
        }} text={getText(item.hero?.subheading && isRichText(item.hero.subheading) ? documentToPlainTextString(item.hero.subheading.json) : 'Hero subheading')} />;
      }
      return <Card key={key} className={className} title={`Resource type not supported: ${item.__typename}`} imageAlt={'Image alt'} linkText="Update this" bgColor={bgColor} />;
    })}
		</div>;
}