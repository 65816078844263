import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';
const contentfulLoader = ({
  src,
  width,
  quality
}: ImageLoaderProps) => {
  const imageFormat = src.split('.')?.at(-1);
  if (imageFormat === 'svg') {
    return src;
  }
  return `${src}?fm=webp&w=${width}&q=${quality || 75}`;
};
const isCtfImage = (src?: string) => src?.includes('ctfassets');
export const Image = ({
  ...props
}: ImageProps & {
  key?: string;
}) => {
  if (typeof props.src === 'string' && isCtfImage(props.src)) {
    return <NextImage loader={contentfulLoader} {...props} />;
  }
  return <NextImage {...props} data-sentry-element="NextImage" data-sentry-component="Image" data-sentry-source-file="index.tsx" />;
};