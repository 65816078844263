import { MouseEvent, MutableRefObject, useEffect } from 'react';
import { clsx } from 'clsx';
import 'keen-slider/keen-slider.min.css';
import { KeenSliderInstance, KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import { onImageError } from '@/utils/sentry/onImageError';
import { Image } from '@/components/Image';
import { Button } from '../Button';
import { Warning } from '../Warning';
type Slide = {
  quote?: string;
  authorName?: string;
  authorTitle?: string;
  link?: {
    href: string;
    onClick?(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  linkText: string;
  logo?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  logoAlt: string;
  mainImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  mainImageAlt: string;
};
type Props = {
  className?: string;
  slides: Slide[];
  variant?: 'light' | 'dark';
};
function SyncSliderPlugin(mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove('active');
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active');
    }
    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }
    slider.on('created', () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on('animationStarted', main => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}
export function LogoCarousel({
  className,
  slides,
  variant = 'light'
}: Props) {
  const SLIDE_COUNT = slides.length;
  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0
  });
  const [thumbnailRef, thumbnailInstanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: SLIDE_COUNT
    }
  }, [SyncSliderPlugin(sliderInstanceRef)]);
  const [dotsRef, dotsInstanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: SLIDE_COUNT
    }
  }, [SyncSliderPlugin(sliderInstanceRef)]);

  // When user resize the component, we need to update the sliders to recalculate the slide width.
  // The className is changed whenever user changes the size or style of the component.
  useEffect(() => {
    sliderInstanceRef.current?.update();
    thumbnailInstanceRef.current?.update();
    dotsInstanceRef.current?.update();
  }, [className, thumbnailInstanceRef, sliderInstanceRef, dotsInstanceRef]);
  if (slides?.length === 0) return <Warning className={className}>There are no slides</Warning>;
  const buttonSettings = {
    dark: '[&>svg]:!fill-white !text-white',
    light: '[&>svg]:!fill-bc-black !text-bc-black'
  };
  return <div className={clsx(className, 'flex flex-col')} data-sentry-component="LogoCarousel" data-sentry-source-file="LogoCarousel.tsx">
			<div ref={sliderRef} className="keen-slider pt-16 lg:order-2">
				{slides?.map((slide, i) => {
        return <div key={i} className="keen-slider__slide flex text-center lg:text-left">
							<div className={clsx('flex-1 lg:pr-4', {
            light: 'text-bc-black',
            dark: 'text-white'
          }[variant])}>
								{slide.logo ? <Image src={slide.logo.url} alt={slide.logoAlt} width={slide.logo.dimensions.width} height={slide.logo.dimensions.width / (slide.logo.dimensions.width / slide.logo.dimensions.height)} className="inline-flex lg:hidden mb-14" onError={onImageError} /> : null}

								<blockquote className="font-medium text-lg px-7 lg:px-0">{slide.quote}</blockquote>
								<cite className="block mt-5 text-eyebrow px-7 lg:px-0 uppercase not-italic">
									<span className="font-bold mr-2">{slide.authorName}</span>
									<span className="">{slide.authorTitle}</span>
								</cite>

								{slide.link?.href !== '#' && slide.link?.href ? <>
										<div className={clsx('block w-10 h-[2px]  my-8 mx-auto lg:mx-0', {
                light: 'bg-bc-black',
                dark: 'bg-white'
              }[variant])}></div>
										<Button variant="subtle" className={buttonSettings[variant]} size="large" link={slide.link} showIcon={true}>
											{slide.linkText}
										</Button>
									</> : null}
							</div>

							{slide.mainImage ? <div className="hidden lg:block w-3/5 pl-4">
									<Image src={slide.mainImage.url} alt={slide.mainImageAlt} width={slide.mainImage.dimensions.width} height={slide.mainImage.dimensions.width / (slide.mainImage.dimensions.width / slide.mainImage.dimensions.height)} className="w-full" onError={onImageError} />
								</div> : null}
						</div>;
      })}
			</div>

			<div ref={thumbnailRef} className="keen-slider order-1 !hidden lg:!flex">
				{slides?.map((slide, i) => {
        return <div key={i} className="keen-slider__slide group flex justify-center items-center text-center box-border max-w-full h-24 border-b border-gray-900 cursor-pointer [&.active]:border-bc-blue [&.active]:border-b-[5px]">
							{slide.logo ? <Image src={slide.logo.url} alt={slide.logoAlt} width={slide.logo.dimensions.width} height={slide.logo.dimensions.width / (slide.logo.dimensions.width / slide.logo.dimensions.height)} className="inline-flex opacity-30 transition-opacity group-[.active]:opacity-100 group-hover:opacity-100 mb-5 max-h-14 w-auto" onError={onImageError} /> : null}
						</div>;
      })}
			</div>

			<div ref={dotsRef} className="keen-slider flex space-x-1.5 mt-14 sm:mt-20 justify-center lg:!hidden">
				{slides?.map((slide, i) => {
        return <div key={i} className="keen-slider__slide group flex items-center justify-center !flex-auto !min-w-0 !max-w-[20px] h-5 cursor-pointer">
							<div className={clsx('w-1.5 h-1.5 rounded-full opacity-25 transition-opacity group-[.active]:opacity-100 group-hover:opacity-100', {
            light: 'bg-bc-black',
            dark: 'bg-white'
          }[variant])}></div>
						</div>;
      })}
			</div>
		</div>;
}