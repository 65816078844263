import dynamic from 'next/dynamic'

import { Combobox } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/WistiaVideo/WistiaVideo.makeswift'

// eslint-disable-next-line no-unused-vars
const { url, ...rest } = props

runtime.registerComponent(
	//@ts-expect-error remove this once Select supports numbers
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CaseStudyVideo').then(({ CaseStudyVideo }) => CaseStudyVideo)))
	),
	{
		type: 'case-study-video',
		label: 'Contentful/Case Study/Case Study Video',
		props: {
			...rest,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'CaseStudy',
						filter: (name) => name === 'AssetExternal',
						query
					})
				}
			})
		}
	}
)
