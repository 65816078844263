import dynamic from 'next/dynamic'

import { Checkbox, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	url: TextInput({
		label: 'Share URL',
		defaultValue: 'https://makeswift.wistia.com/medias/460gpav9kl',
		selectAll: true
	}),
	aspectRatio: Select({
		label: 'Aspect Ratio',
		options: [
			//@ts-expect-error remove this once Select supports numbers
			{ value: 16 / 10, label: '16:10' },
			//@ts-expect-error remove this once Select supports numbers
			{ value: 16 / 9, label: '16:9' },
			//@ts-expect-error remove this once Select supports numbers
			{ value: 4 / 3, label: '4:3' },
			//@ts-expect-error remove this once Select supports numbers
			{ value: 1, label: '1:1' }
		],
		//@ts-expect-error remove this once Select supports numbers
		defaultValue: 16 / 9
	}),
	popover: Checkbox({ label: 'Popover' })
}

runtime.registerComponent(
	//@ts-expect-error remove this once Select supports numbers
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./WistiaVideo').then(({ WistiaVideo }) => WistiaVideo)))
	),
	{
		type: 'wistia-video',
		label: 'Wistia Video',
		props
	}
)
