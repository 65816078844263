import { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';
import { Link } from '@/components/CustomLink';
import { getValidColor, getValidVariant } from './helpers/validateProps';
export type ButtonContentProps = {
  children: React.ReactNode;
  variant?: 'filled' | 'subtle' | 'outlined';
  size?: 'small' | 'default' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  showIcon?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  className?: string;
  shouldSkipTrailingSlash?: boolean;
};
type Props = ButtonContentProps & Omit<ComponentPropsWithoutRef<'button'>, keyof ButtonContentProps>;
type ButtonStyles = {
  variant?: 'filled' | 'subtle' | 'outlined';
  size?: 'small' | 'default' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  showIcon?: boolean;
  fullWidth?: boolean;
  className?: string;
};
export function getButtonStyles({
  variant = 'filled',
  size = 'default',
  color = 'primary',
  showIcon = false,
  fullWidth = false,
  className
}: ButtonStyles) {
  return clsx('group relative inline-flex items-center border duration-150 font-medium antialiased transition rounded-md button uppercase tracking-wider', {
    filled: {
      primary: 'bg-bc-blue border-bc-blue text-white hover:bg-blue-200 hover:border-blue-200 active:bg-bc-blue active:border-bc-blue group-hover:bg-blue-200 group-hover:border-blue-200 group-active:bg-bc-blue group-active:border-bc-blue',
      secondary: 'bg-black-100 border-black-100 text-white hover:bg-gray-500 hover:border-gray-500 active:bg-black-100 active:border-black-100 group-hover:bg-gray-500 group-hover:border-gray-500 group-active:bg-black-100 group-active:border-black-100',
      tertiary: 'bg-white border-white text-black-100 hover:bg-gray-1100 hover:border-gray-1100 active:bg-white active:border-white group-hover:bg-gray-1100 group-hover:border-gray-1100 group-active:bg-white group-active:border-white',
      quaternary: 'bg-gray-900 border-none text-white '
    },
    outlined: {
      primary: 'border-bc-blue text-bc-blue hover:bg-gray-1200 active:bg-transparent active:border-bc-blue group-hover:bg-gray-1200 group-active:bg-transparent group-active:border-bc-blue',
      secondary: 'border-black text-black hover:bg-gray-1200 active:bg-transparent group-hover:bg-gray-1200 group-active:bg-transparent',
      tertiary: 'border-white text-white hover:bg-white/20 active:bg-transparent group-hover:bg-white/20 group-active:bg-transparent',
      quaternary: 'border-gray-900 text-gray-600 hover:bg-gray-1200 active:bg-transparent group-hover:bg-gray-1200 group-active:bg-transparent'
    },
    subtle: {
      primary: 'border-transparent !p-0 text-bc-blue hover:text-bc-blue active:text-black-100  group-hover:text-black-100 group-active:text-black-100 [&>svg]:fill-bc-blue',
      secondary: 'border-transparent !p-0 text-black-100 hover:text-bc-blue active:text-black-100  group-hover:text-bc-blue group-active:text-bc-blue [&>svg]:fill-bc-blue',
      tertiary: 'border-transparent !p-0 text-white hover:text-bc-green active:text-white group-hover:text-bc-green group-active:text-bc-green [&>svg]:fill-bc-green',
      quaternary: 'border-transparent !p-0 text-black-100 hover:text-bc-blue active:text-black-100  group-hover:text-bc-blue group-active:text-bc-blue [&>svg]:fill-bc-blue'
    }
  }[getValidVariant(variant)][getValidColor(color)], {
    small: 'py-1 px-4 text-xxs',
    default: 'py-2 px-6 text-xs',
    large: 'py-3 px-7 text-sm'
  }[size], className, showIcon ? 'text-left' : 'text-center', fullWidth ? 'w-full justify-center' : '');
}
export function ButtonContent({
  children,
  showIcon = false,
  isLoading = false,
  fullWidth = false
}: ButtonContentProps) {
  return <>
			<span className={clsx(!fullWidth ? 'flex-1' : '')}>{children}</span>

			{showIcon && <svg height={16} width={20} fill="currentColor" viewBox="0 0 20 16" className="ml-2 group-hover:translate-x-1 transition-transform duration-150">
					<path fillRule="evenodd" clipRule="evenodd" d="M17.138 8.84H.84a.839.839 0 1 1 0-1.679h16.354L13.508 3.42a.839.839 0 1 1 1.213-1.16l5.044 5.14a.84.84 0 0 1-.013 1.173l-5.044 5.182a.839.839 0 1 1-1.187-1.186l3.617-3.729Z" />
				</svg>}

			<span className={clsx(`${isLoading ? 'w-3 h-3 ml-3 opacity-100' : 'w-0 h-0 ml-0 opacity-0'}`, 'bg-white [clip-path:polygon(0_0,100%_0,100%_100%)] transition-all animate-[buttonLoader_2s_cubic-bezier(.3,1,0,1)_infinite]')}></span>
		</>;
}
export function Button({
  link,
  anchorLink,
  className,
  children,
  isLoading,
  showIcon,
  fullWidth,
  variant,
  size,
  color,
  dataTestId,
  fakeButton = false,
  shouldSkipTrailingSlash,
  ...rest
}: Props & {
  link?: {
    href?: string;
    target?: string;
  };
  anchorLink?: string;
  fakeButton?: boolean;
  dataTestId?: string;
}) {
  if (fakeButton) {
    return <span className={clsx(getButtonStyles({
      variant,
      size,
      color,
      showIcon,
      fullWidth,
      className
    }), 'peer-hover:[&>svg]:translate-x-1')}>
				<ButtonContent isLoading={isLoading} showIcon={showIcon} fullWidth={fullWidth}>
					{children}
				</ButtonContent>
			</span>;
  }
  if (anchorLink) {
    return <a href={`#${anchorLink}`} className={getButtonStyles({
      variant,
      size,
      color,
      showIcon,
      fullWidth,
      className
    })}>
				<ButtonContent isLoading={isLoading} showIcon={showIcon} fullWidth={fullWidth}>
					{children}
				</ButtonContent>
			</a>;
  }
  if (link?.href) {
    return <Link href={link.href} shouldSkipTrailingSlash={shouldSkipTrailingSlash} target={link.target} className={getButtonStyles({
      variant,
      size,
      color,
      showIcon,
      fullWidth,
      className
    })} dataTestId={dataTestId}>
				<ButtonContent isLoading={isLoading} showIcon={showIcon} fullWidth={fullWidth}>
					{children}
				</ButtonContent>
			</Link>;
  }
  return <button {...rest} className={getButtonStyles({
    variant,
    size,
    color,
    showIcon,
    fullWidth,
    className
  })} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
			<ButtonContent isLoading={isLoading} showIcon={showIcon} fullWidth={fullWidth} data-sentry-element="ButtonContent" data-sentry-source-file="Button.tsx">
				{children}
			</ButtonContent>
		</button>;
}