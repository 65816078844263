import dynamic from 'next/dynamic'

import { Image, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./WistiaImage').then(({ WistiaImage }) => WistiaImage)))
	),
	{
		type: 'wistia-image',
		label: 'Wistia Image',
		props: {
			className: Style(),
			url: TextInput({
				label: 'Share URL',
				defaultValue: 'https://makeswift.wistia.com/medias/460gpav9kl',
				selectAll: true
			}),
			image: Image({ label: 'Custom thumbnail', format: Image.Format.WithDimensions }),
			imageAlt: TextInput({
				label: 'Alt text',
				defaultValue: 'Video thumbnail',
				selectAll: true
			})
		}
	}
)
