import clsx from 'clsx';
import { Link } from '@/components/CustomLink';
type Props = {
  className?: string;
  link?: {
    href: string;
    target?: '_self' | '_blank';
  };
  children?: React.ReactNode;
};
export function Tag({
  className,
  link,
  children
}: Props) {
  return link ? <Link href={link.href} target={link.target} className={clsx('inline-block border border-gray-900 text-gray-400 rounded-md py-0.5 px-3 text-sm hover:bg-gray-1300 transition-colors', className)}>
			{children}
		</Link> : <button className={clsx('inline-block border border-gray-900 text-gray-400 rounded-md py-0.5 px-3 text-sm hover:bg-gray-1300 transition-colors', className)}>
			{children}
		</button>;
}