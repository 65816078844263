import React from 'react';
import clsx from 'clsx';
import { onImageError } from '@/utils/sentry/onImageError';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
import { Button } from '../Button/Button';
import { Tag } from '../Tag';
type Category = {
  text?: string;
  link?: {
    href: string;
    target?: '_self' | '_blank';
  };
};
type Props = {
  className?: string;
  link?: {
    href: string;
    target?: '_self' | '_blank';
  };
  image?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  } | null;
  imageAlt?: string | null;
  categories?: Category[];
  author?: string | null;
  buttonTitle?: string | null;
  title?: string | null;
  size?: 'medium' | 'large';
};
export function PostPreview({
  className,
  image,
  imageAlt,
  link,
  categories,
  title,
  author,
  buttonTitle,
  size = 'medium'
}: Props) {
  return <article className={clsx(className, 'w-full')} data-testid="blog_article_preview" data-sentry-component="PostPreview" data-sentry-source-file="PostPreview.tsx">
			{image && <div className="group block overflow-hidden mb-7">
					{link ? <Link {...link}>
							<Image src={image.url} alt={imageAlt ?? ''} width={image?.dimensions.width} height={image?.dimensions.height} className="w-full group-hover:scale-110 aspect-video object-cover duration-300 transition-transform" onError={onImageError} />
						</Link> : <Image src={image.url} alt={imageAlt ?? ''} width={image?.dimensions.width} height={image?.dimensions.height} className="w-full group-hover:scale-110 duration-300 transition-transform" onError={onImageError} />}
				</div>}
			{categories && categories.length > 0 && <div className={clsx('flex gap-2 mb-5 flex-wrap', className)}>
					{categories.map(({
        link,
        text
      }, index) => <Tag key={index} link={link}>
							{text}
						</Tag>)}
				</div>}

			<p className="text-sm text-gray-400 mb-4">{author}</p>
			<Link href={link?.href ?? ''} className={clsx({
      medium: 'text-lg font-bold',
      large: 'text-h4 sm:text-h3'
    }[size], 'block text-bc-black mb-5')} data-sentry-element="Link" data-sentry-source-file="PostPreview.tsx">
				<h3 className="cursor-pointer">{title}</h3>
			</Link>

			<Button variant="subtle" color="secondary" size="large" link={link} showIcon={true} data-sentry-element="Button" data-sentry-source-file="PostPreview.tsx">
				{buttonTitle || 'Read now'}
			</Button>
		</article>;
}