import { AnchorHTMLAttributes, ReactNode } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { DRIFT_REF } from '@/lib/constants';
import { getLocalizedFromEnglish } from '@/utils/locales/localized-routes/getLocalizedFromEnglish';
import { removeTrailingSlash } from '@/utils/locales/localized-routes/helpers/removeTrailingSlash';
import { isFile } from '@/utils/string/fileExtensions';
type Props = LinkProps & {
  href: LinkProps['href'];
  children?: ReactNode;
  className?: string;
  shouldSkipTrailingSlash?: boolean;
  dataTestId?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;
const APP_PATHS = ['/blog', '/events', '/make-it-big', '/theme-store', '/theme', '/apps', '/company', '/empresa', '/societe', '/azienda', '/selskap', '/firma', '/foretag', '/bedrijf', '/unternehmen', '/showcase', '/ejemplos', '/vitrine', '/vetrina', '/vis-frem', '/fremvisning', '/exempel', '/voorbeeld', '/beispiel', '/case-study', '/caso-de-estudio', '/etude-de-cas', '/caso-studio', '/casestudy', '/fallstudie', '/press', '/presse', '/prensa', '/stampa', '/pers', '/articles', '/articulos', '/articoli', '/artikelen', '/artikel', '/resources', '/ressourcen', '/bronnen', '/risorse', '/ressources', '/recursos'];
export function Link({
  href,
  children,
  shouldSkipTrailingSlash,
  dataTestId,
  ...rest
}: Props) {
  // Checks if link is for internal app pages
  const isAppPath = APP_PATHS.some(path => href.toString().startsWith(path));
  const {
    locale = 'en-US'
  } = useRouter();

  // Adds trailing slash if missing
  const hrefWithSlash = (hrefString: string) => {
    let resultString = hrefString;
    if (!hrefString.startsWith('#') && !hrefString.endsWith('/') && !shouldSkipTrailingSlash && !hrefString.startsWith('https://support.bigcommerce.com/') && !isFile(hrefString) && !hrefString.startsWith('tel:') && !hasQueryParams(hrefString)) {
      resultString = `${href.toString()}/`;
    }
    return resultString;
  };
  const hasQueryParams = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.searchParams && parsedUrl.searchParams.toString() !== '';
    } catch (error) {
      return false;
    }
  };

  // Localizes the pathname if available
  const localizedPath = getLocalizedFromEnglish((locale as Locale), hrefWithSlash(href.toString()));
  const defaultTestId = isAppPath ? 'custom-next-link' : 'custom-anchor-tag';
  const localizedPathFormat = localizedPath.includes(DRIFT_REF) || shouldSkipTrailingSlash ? removeTrailingSlash(localizedPath) : localizedPath;
  // Updating to display always anchor tag, temporary for fix 4A
  // After migration we need to take the isAppPath back and remove the anchor
  if (false) {
    return <NextLink data-testid={dataTestId ? dataTestId : defaultTestId} prefetch={false} href={localizedPathFormat} {...rest}>
				{children}
			</NextLink>;
  }
  return <a data-testid={dataTestId ? dataTestId : defaultTestId} href={localizedPathFormat} {...rest} data-sentry-component="Link" data-sentry-source-file="index.tsx">
			{children}
		</a>;
}