import { ComponentPropsWithoutRef } from 'react';
import { useRouter } from 'next/router';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import useSWR from 'swr';
import { FeaturedLogoCarouselDocument } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { isDevelopment } from '@/utils/isDevelopment';
import { isNonNullable } from '@/utils/isNonNullable';
import getLocalizedPathFromEnglish from '@/utils/locales/localized-routes/getLocalizedPathFromEnglish';
import { FullScreenLoading } from '@/components/FullScreenLoading';
import { LogoCarousel } from '@/components/LogoCarousel';
import { Warning } from '@/components/Warning';
import { isRichText, serializeCacheKey } from '../../../../../lib/contentful/utils';
export const FEATURED_LOGO_CAROUSEL_DEFAULT_TAG = 'featuredTest';
export const FEATURED_LOGO_CAROUSEL_DEFAULT_COUNT = 4;
type Props = {
  className?: string;
  tag?: string;
  count?: number;
  variant?: 'light' | 'dark';
};
export function FeaturedLogoCarousel({
  className,
  tag = FEATURED_LOGO_CAROUSEL_DEFAULT_TAG,
  count = FEATURED_LOGO_CAROUSEL_DEFAULT_COUNT,
  variant = 'light'
}: Props) {
  const {
    locale = 'en-US',
    isPreview
  } = useRouter();
  const contentful = client({
    preview: isPreview
  });
  const {
    data,
    error,
    isLoading
  } = useSWR(serializeCacheKey('featuredLogoCarousel', {
    locale,
    tag,
    count
  }), () => contentful.request(FeaturedLogoCarouselDocument, {
    where: {
      contentfulMetadata: {
        tags_exists: true,
        tags: {
          id_contains_some: [tag]
        }
      }
    },
    limit: count,
    locale,
    preview: isPreview
  }));
  const caseStudies = data?.caseStudyCollection?.items.filter(isNonNullable).sort((a, b) => (a?.featuredOrder ?? -Infinity) - (b?.featuredOrder ?? -Infinity)).map(caseStudy => {
    const {
      featuredLogo,
      featuredImage
    } = caseStudy;
    const slide: ComponentPropsWithoutRef<typeof LogoCarousel>['slides'][number] = {
      quote: isRichText(caseStudy?.featuredContent) ? documentToPlainTextString(caseStudy.featuredContent.json) : undefined,
      authorName: caseStudy.featuredAuthorName ?? undefined,
      authorTitle: caseStudy.featuredAuthorTitle ?? undefined,
      link: {
        href: `${getLocalizedPathFromEnglish(locale, '/case-study/')}${caseStudy.slug}/`
      },
      linkText: caseStudy.featuredLinkText ?? 'Read more',
      logo: featuredLogo?.url && featuredLogo?.width && featuredLogo?.height ? {
        url: featuredLogo.url,
        dimensions: {
          width: featuredLogo.width,
          height: featuredLogo.height
        }
      } : undefined,
      logoAlt: caseStudy.title ?? 'Case study logo',
      mainImage: featuredImage?.url && featuredImage?.width && featuredImage?.height ? {
        url: featuredImage.url,
        dimensions: {
          width: featuredImage.width,
          height: featuredImage.height
        }
      } : undefined,
      mainImageAlt: caseStudy.title ?? 'Case study main logo'
    };
    return slide;
  }) ?? [];
  if (isLoading) return <FullScreenLoading />;
  if (error) {
    if (isDevelopment()) {
      return <Warning>{error?.message || 'An error occurred'}</Warning>;
    } else {
      console.error(error);
      return null;
    }
  }
  if (caseStudies?.length === 0) {
    if (isDevelopment()) {
      return <Warning className={className}>No featured case study found</Warning>;
    }
    return null;
  }
  return <LogoCarousel className={className} variant={variant} slides={caseStudies} data-sentry-element="LogoCarousel" data-sentry-component="FeaturedLogoCarousel" data-sentry-source-file="FeaturedLogoCarousel.tsx" />;
}