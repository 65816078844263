type Props = {
  show?: boolean;
};
export function FullScreenLoading({
  show = true
}: Props) {
  return <div className={`bg-transparent absolute inset-0 z-50 min-h-screen flex items-start justify-center py-24${show ? '' : ' hidden'}`} data-sentry-component="FullScreenLoading" data-sentry-source-file="FullScreenLoading.tsx">
			<div className="flex gap-x-1">
				<span className="bg-[#4b71fc] w-2.5 h-16 animate-loading-pulse" />
				<span className="bg-[#4b71fc] w-2.5 h-16 animate-loading-pulse [animation-delay:200ms]" />
				<span className="bg-[#4b71fc] w-2.5 h-16 animate-loading-pulse [animation-delay:400ms]" />
				<span className="bg-[#4b71fc] w-2.5 h-16 animate-loading-pulse [animation-delay:600ms]" />
			</div>
		</div>;
}